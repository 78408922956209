import React from 'react';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import SlickCSS from '../../about-us-page/slick-css';
import * as S from './styles';
import { colors } from '../../../../utils/helpers';

export const ToolkitSnippet = ({ image, title, subtitle }) => {
	return (
		<S.ToolkitSnipperWrapper>
			<img src={image.file.url} alt={title} />
			<h3>{title}</h3>
			<p>{subtitle}</p>
		</S.ToolkitSnipperWrapper>
	);
};

export const CustomerToolkitSlider = ({ title, settings, toolkits }) => {
	return (
		<>
			<S.HomePageCustomersTitleWrapper>{title}</S.HomePageCustomersTitleWrapper>
			<S.CustomersToolkitContainer>
				<SlickCSS />
				<Slider {...settings}>
					{toolkits.map(toolkit => (
						<Img fluid={toolkit.fluid} />
					))}
				</Slider>
			</S.CustomersToolkitContainer>
		</>
	);
};

export const UsecasesToolkitSlider = ({ toolkits }) => {
	const settings = {
		dots: false,
		autoplay: true,
		infinite: true,
		pauseOnHover: true,
		speed: 3500,
		autoplaySpeed: 0,
		slidesToShow: 3,
		cssEase: 'linear',
		swipeToSlide: true,
		centerMode: true,
		lazyload: true,
		focusOnSelect: true,
		rtl: false,
		responsive: [
			{
				breakpoint: 1700,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 1349,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 925,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	return (
		<>
			<S.UseCaseToolkitContainer>
				<SlickCSS />
				<Slider {...settings}>
					{toolkits.map((toolkit, index) => {
						return (
							<S.UseCaseToolkitWrapper bgColor={colors[index]}>
								<S.UseCaseTitle>{toolkit.section1Title}</S.UseCaseTitle>
								<Img
									fluid={toolkit.homepageImage.fluid}
									style={{ width: '100%', overflow: 'visible' }}
									imgStyle={{ objectFit: 'fill' }}
								/>
							</S.UseCaseToolkitWrapper>
						);
					})}
				</Slider>
			</S.UseCaseToolkitContainer>
		</>
	);
};

const ToolkitSlider = ({ settings, toolkits }) => {
	return (
		<S.ToolkitContainer>
			<SlickCSS />
			<Slider {...settings}>
				{toolkits.map((toolkit, index) => (
					<ToolkitSnippet
						title={toolkit.title}
						subtitle={toolkit.description}
						image={toolkit}
					/>
				))}
			</Slider>
		</S.ToolkitContainer>
	);
};

export default ToolkitSlider;
