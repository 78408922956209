import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import SEO from '../components/global/seo';
import Layout from '../components/global/layout/Layout';
import VerticalSlider from '../components/global/ImageSlider/VerticalSlider';
import CustomerStory from '../components/global/CustomerStory';
import CustomerHolder from '../components/customers-page/customer-holder/CustomerHolder';
import ToolkitSlider from '../components/global/ToolKit/ToolkitSlider';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import InfoBox from '../components/global/info-box/InfoBox';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import InfoImgBox from '../components/bot-builder-page/InfoImgBox/InfoImgBox';
import MasterCTA from '../components/global/CTA/MasterCTA/MasterCTA.component';
import InfoCard from '../components/global/InfoCard/InfoCard.component';
import ImageWrapper from '../components/global/ImageWrapper/ImageWrapper';
import { setColors } from '../../utils/helpers';
import {
	SliderContainer,
	SliderWrapper,
} from '../components/customer-support/styles';
import { HeroWrapper } from '../components/demand-generation/styles';
import { Container } from '../components/global/customers/styles';
import { InfoCardGrid } from '../components/global/InfoCard/InfoCard.styled';
import * as S from '../components/bot-builder-page/styles';

export const sliderProps = {
	theme: 'dark',
	gridLayout: '2fr 1fr',
	cardPadding: '25px',
	cardAlignmet: 'center',
	borderRadius: '10px',
	imgStyle: { padding: '0 15px' },
	cardStyle: { margin: '0px auto' },
};

export const settings = {
	dots: false,
	autoplay: true,
	infinite: true,
	speed: 3500,
	autoplaySpeed: 3500,
	slidesToShow: 7,
	cssEase: 'linear',
	swipeToSlide: true,
	centerMode: true,
	lazyload: true,
	responsive: [
		{
			breakpoint: 1700,
			settings: {
				slidesToShow: 6,
			},
		},
		{
			breakpoint: 1350,
			settings: {
				slidesToShow: 5,
			},
		},
		{
			breakpoint: 1100,
			settings: {
				slidesToShow: 4,
			},
		},
		{
			breakpoint: 850,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 650,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 468,
			settings: {
				slidesToShow: 1,
			},
		},
		{
			breakpoint: 375,
			settings: {
				slidesToShow: 1,
			},
		},
		{
			breakpoint: 300,
			settings: {
				centerMode: false,
				slidesToShow: 1,
			},
		},
	],
};

const BotBuilder = ({ data }) => {
	const {
		seo,
		section1Title,
		section1Subtitle,
		section1Button,
		section1PlayButton,
		section1Image,
		section2Title,
		section2SubtitleLong,
		section2Image,
		section3Title,
		section3Subtitle,
		section3Image1,
		section3Image1Link,
		section3Image2,
		section3Image2Link,
		section4Title,
		section4Subtitle,
		section4ToolKit,
		section5Title,
		section5Subtitle,
		section5Image1,
		section5Image1Link,
		section5Image2,
		section5Image2Link,
		section6HeroImage,
		section6Logo,
		section6Feedback,
		section6Name,
		section6Designation,
		section6StorySlug,
		section7Title,
		section7Subtitle,
		section7Features,
		section8Title,
		section8Subtitle,
		section8Features,
		section9Title,
		section9UseCasesRef,
		section9Link,
		section10Title,
		section10Cards,
		cta,
	} = data.contentfulPageBotBuilder;

	return (
		<>
			<Layout>
				<SEO {...seo} />
				<WrapperOne>
					<HeroWrapper>
						<InfoBox
							image={section1Image}
							title={section1Title}
							subtitle={section1Subtitle}
							hideImage="true"
							buttonText={section1Button?.text}
							buttonLink={section1Button?.link}
							playButtonLink={section1PlayButton}
							videoBorderRadius={'10px'}
							isHeroHeader
						/>
					</HeroWrapper>
				</WrapperOne>
				<WrapperOne>
					<S.TextBoxWrapper>
						<SectionTextBox
							title={section2Title}
							subtitle={section2SubtitleLong.section2SubtitleLong}
							largeHeading="true"
						/>
						<ImageWrapper
							image={section2Image}
							imageStyles={{ maxWidth: '1150px', margin: '0 auto' }}
						/>
					</S.TextBoxWrapper>
				</WrapperOne>
				<WrapperOne>
					<InfoImgBox
						title={section3Title}
						subtitle={section3Subtitle}
						image1={section3Image1}
						image1Link={section3Image1Link}
						image2={section3Image2}
						image2Link={section3Image2Link}
					/>
				</WrapperOne>
				<S.ToolkitWrapper>
					<SectionTextBox
						title={section4Title}
						subtitle={section4Subtitle}
						setHeadersGap={10}
					/>
					<ToolkitSlider settings={settings} toolkits={section4ToolKit} />
				</S.ToolkitWrapper>
				<WrapperOne>
					<InfoImgBox
						title={section5Title}
						subtitle={section5Subtitle}
						image1={section5Image1}
						image1Link={section5Image1Link}
						image2={section5Image2}
						image2Link={section5Image2Link}
					/>
				</WrapperOne>
				<WrapperOne setBgColor={setColors.lightBlue}>
					<S.CustomerStoryWrapper>
						<CustomerStory
							customerStoryImage={section6HeroImage}
							customerStoryLogo={section6Logo}
							customerStoryText={section6Feedback}
							customerName={section6Name}
							customerTitle={section6Designation}
							cusomterSlug={section6StorySlug}
						></CustomerStory>
					</S.CustomerStoryWrapper>
				</WrapperOne>
				<SliderContainer>
					<SectionTextBox title={section7Title} subtitle={section7Subtitle} />
					<SliderWrapper>
						<VerticalSlider
							data={section7Features}
							noBottomPadding={false}
							{...sliderProps}
						/>
					</SliderWrapper>
				</SliderContainer>
				<SliderContainer
					style={{ 'background-color': setColors.gray, paddingTop: '20px' }}
				>
					<SectionTextBox title={section8Title} subtitle={section8Subtitle} />
					<SliderWrapper>
						<VerticalSlider
							data={section8Features}
							noBottomPadding={false}
							{...sliderProps}
						/>
					</SliderWrapper>
				</SliderContainer>
				<WrapperOne>
					<S.UsecaseWrapper>
						<SectionTextBox
							setHeadersGap={50}
							largeHeading={true}
							title={section9Title}
						/>
						<CustomerHolder
							customers={section9UseCasesRef}
							isUsecase
							marginEffect={false}
						/>
						<div className="sectionLink">
							{section9Link && section9Link.link && (
								<Link to={section9Link.link} target="_self">
									{section9Link.text}
								</Link>
							)}
						</div>
					</S.UsecaseWrapper>
				</WrapperOne>
				<WrapperOne>
					<SectionTextBox
						setHeadersGap={30}
						largeHeading={true}
						title={section10Title}
					/>
					<InfoCardGrid style={{ marginTop: '20px' }}>
						{section10Cards.map(card => (
							<InfoCard
								image={card.icon}
								title={card.title}
								subtile={card.description.description}
								linkText={card.linkText}
								linkUrl={card.linkUrl}
							/>
						))}
					</InfoCardGrid>
				</WrapperOne>
				<S.CTAWrapper>
					<Container>
						<MasterCTA cta={cta} />
					</Container>
				</S.CTAWrapper>
			</Layout>
		</>
	);
};

export const pageQuery = graphql`
	{
		contentfulPageBotBuilder {
			seo {
				...seo
			}
			section1Title
			section1Subtitle
			section1Button {
				text
				link
			}
			section1PlayButton {
				text
				link
			}
			section1Image {
				fluid(maxWidth: 1000, maxHeight: 700) {
					...GatsbyContentfulFluid
				}
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
				title
			}
			section2Title
			section2SubtitleLong {
				section2SubtitleLong
			}
			section2Image {
				...asset
			}
			section3Title
			section3Subtitle
			section3Image1 {
				...asset
			}
			section3Image1Link {
				text
				link
			}
			section3Image2 {
				...asset
			}
			section3Image2Link {
				text
				link
			}
			section4Title
			section4Subtitle
			section4ToolKit {
				title
				description
				file {
					url
					contentType
				}
			}
			section5Title
			section5Subtitle
			section5Image1 {
				...asset
			}
			section5Image1Link {
				text
				link
			}
			section5Image2 {
				...asset
			}
			section5Image2Link {
				text
				link
			}

			section6HeroImage {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section6Logo {
				fixed(width: 125) {
					...GatsbyContentfulFixed
				}
				title
			}
			section6Feedback
			section6Name
			section6Designation
			section6StorySlug
			section7Title
			section7Subtitle
			section7Features {
				...asset
			}
			section8Title
			section8Subtitle
			section8Features {
				...asset
			}
			section9Title
			section9Link {
				text
				link
			}
			section9UseCasesRef {
				...useCaseCard
			}
			section10Title
			section10Cards {
				title
				description {
					description
				}
				icon {
					file {
						url
					}
					title
				}
				linkUrl
				linkText
			}
			cta {
				...cta
			}
		}
	}
`;
export default BotBuilder;
