import styled from 'styled-components';
import {
	setColors,
	setFontFamily,
	setFontWeight,
	setFontSize,
	mediaQuery,
} from '../../../../utils/helpers';

export const CustomerStoryWrapper = styled.div`
	display: flex;
	${mediaQuery.mobileXL`
		flex-direction: column;

		#description-container{
			width: 100%;
		}
	`}
`;

export const ImageContainer = styled.div`
	position: relative;
	box-sizing: border-box;
	/* max-width: 470px; */
	width: 43%;
	.coverImage {
		border-radius: 10px 0 0 10px;
		height: 100%;
		width: auto;
	}
	${mediaQuery.mobileXL`
		width: 100%;
		img{
			min-width: 100%;
			border-radius: 10px 10px 0 0;
		}
		.gatsby-image-wrapper{
			min-width: 100%;
		}
	`}
`;

export const LogoImageContainer = styled.div`
	max-width: 192px;
	max-height: 70px;
	width: 100%;
	background-color: white;
	position: absolute;
	left: 28px;
	bottom: 17px;
	width: 100%;
	border-radius: 5px;
	padding: 5px;
	text-align: center;

	img {
		height: 70px;
		width: 192px;
		object-fit: contain;
	}

	${mediaQuery.tabletXS`
		max-width: 150px;
		max-height: 60px;
		left: 10px;
		bottom: 10px;

		img {
			height: 60px;
			width: 150px;
		}
	`}
`;

export const CoverImageWrapper = styled.div`
	z-index: -1;
	height: 480px;
	width: 100%;

	img {
		height: 100%;
		width: 100%;
		object-fit: fill;
		border-radius: 10px 0 0 10px;
	}

	${mediaQuery.mobile700`
		height: 320px;
	`}
`;

export const DescriptionContainer = styled.div`
	background: ${setColors.lightestBlue};
	padding: 0px 40px;
	border-radius: 0 10px 10px 0;
	width: 57%;
	box-sizing: border-box;
	position: relative;
	height: 480px;
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		display: -webkit-box;
		max-width: 100%;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		overflow: hidden;
		margin: 0;
		border: 0;
		font-weight: ${setFontWeight.normal450};
		font-family: ${setFontFamily.book};
		line-height: 46px;
		font-size: ${setFontSize.mediumSmall};

		${mediaQuery.laptopXL`
			font-size: ${setFontSize.mediumSmall};
		`}
		${mediaQuery.tabletL`
			line-height: 42px;
		`}
		${mediaQuery.mobileXL`
			font-size: ${setFontSize.smaller};
			line-height: 36px;
			-webkit-line-clamp: 7;
		`}
	}
	.client {
		padding: 30px 0 0 0;
		font-weight: ${setFontWeight.bolder};
		font-family: ${setFontFamily.book};
		box-sizing: border-box;
		.client-name {
			font-family: ${setFontFamily.medium};
			margin: 0;
			font-weight: ${setFontWeight.slightlyBold};
			font-size: ${setFontSize.smaller};
			line-height: 120%;
		}
		.client-designation {
			font-size: ${setFontSize.smaller};
			font-weight: ${setFontWeight.extraBold};
			margin: 0;
			font-family: ${setFontFamily.black};
		}
	}
	.read-story {
		position: absolute;
		bottom: 15px;
		right: 25px;
		a {
			color: ${setColors.themeBlue};
			font-size: ${setFontSize.tiny};
			font-family: ${setFontFamily.book};
			text-decoration: none;
			display: flex;
			align-items: center;
			svg {
				margin-left: 5px;
				width: 17.6px;
			}
		}
	}
	${mediaQuery.mobileXL`
		border-radius: 0 0 10px 10px;
		padding: 30px 15px;
	`}
`;
