import React from 'react';
import * as S from './style';

const CustomerStory = ({
	customerStoryImage,
	customerStoryLogo,
	customerStoryText,
	customerName,
	customerTitle,
	cusomterSlug,
	linkLabel = 'Read Story',
}) => {
	return (
		<S.CustomerStoryWrapper id="customer-story-wrapper">
			<S.ImageContainer id="customer-img-wrapper">
				<S.LogoImageContainer>
					{customerStoryLogo.fixed ? (
						<img
							src={customerStoryLogo.fixed.src}
							alt={customerStoryLogo.title}
						/>
					) : (
						<img
							src={customerStoryLogo.fluid.src}
							alt={customerStoryLogo.title}
						/>
					)}
				</S.LogoImageContainer>
				<S.CoverImageWrapper>
					<img
						src={customerStoryImage.fluid.src}
						alt={customerStoryImage.title}
					/>
				</S.CoverImageWrapper>
			</S.ImageContainer>
			<S.DescriptionContainer id="description-container">
				<div className="inner-container">
					<p>{customerStoryText}</p>
					<div className="client">
						<div className="client-name">{customerName}</div>
						<div className="client-designation">{customerTitle}</div>
					</div>
				</div>
			</S.DescriptionContainer>
		</S.CustomerStoryWrapper>
	);
};

export default CustomerStory;
