import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const ToolkitContainer = styled.div`
	padding: 30px 0px 50px;
	overflow: hidden;
`;

export const UseCaseToolkitContainer = styled.div`
	overflow: hidden;
	padding: 60px 0 40px 0;

	${mediaQuery.desktopXL`
		.slick-slide{
			width: 500px !important;
		}
		.slick-slide.slick-active{
			width: 500px !important;
		}

		.slick-list {
			max-height: 555px !important;
		}
	`}

	${mediaQuery.laptop1351`
		.slick-slide{
			width: 452px !important;
		}
		.slick-slide.slick-active{
			width: 452px !important;
		}
	`}

	@media (max-width: 1351px) {
		.slick-list {
			max-width: 7616px !important;
			max-height: 555px !important;
		}
	}

	${mediaQuery.tabletXS`
		.slick-slide{
			width: 417px !important;
		}
		.slick-slide.slick-active{
			width: 417px !important;
		}
	`}

	@media (max-width: 767px) {
		.slick-list {
			max-width: 666px !important;
			max-height: 555px !important;
		}
	}
`;

export const HomePageCustomersTitleWrapper = styled.div`
	padding: 47px 0 15px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: ${setColors.lightBlack};
	color: ${setColors.white};
	font-family: ${setFontFamily.book};
	font-size: ${setFontSize.small};
	font-weight: ${setFontWeight.normal450};
`;

export const CustomersToolkitContainer = styled.div`
	background-color: ${setColors.lightBlack};
	overflow: hidden;
`;

export const ToolkitSnipperWrapper = styled.div`
	cursor: pointer;
	background-color: ${setColors.white};
	border-radius: 10px;
	padding: 10px;
	min-height: 295px;
	box-sizing: border-box;
	justify-self: center;
	text-align: center;
	margin: 0px 10px;
	img {
		height: 75px;
		width: 75px;
		margin: 10px auto;
	}
	h3 {
		font-size: ${setFontSize.veryVerySmall};
		margin: 15px 0 22px 0;
	}
	p {
		font-size: ${setFontSize.mediumTiny};
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0;
		margin: 0;
	}
`;

export const UseCaseToolkitWrapper = styled.div`
	background-color: ${props => props.bgColor || '#E4EFFB'};
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	padding: 50px 40px 0 40px;
	border-radius: 10px;
	box-shadow: 4px 4px 8px rgba(16, 16, 16, 0.05);
	max-height: 500px;
	max-width: 334px;
	overflow: hidden;

	${mediaQuery.desktopXS`
		max-width: 300px;
	`}

	${mediaQuery.tabletL`
		max-width: 270px;
	`}

	${mediaQuery.mobileM`
		max-width: 250px;
		padding: 50px 20px 0 20px;
	`}
`;

export const UseCaseTitle = styled.div`
	line-height: 30px;
	display: flex;
	align-items: center;
	font-weight: ${setFontWeight.bold};
	font-size: ${setFontSize.smaller};
	font-family: ${setFontFamily.book};
	font-style: normal;
	color: ${setColors.lightBlack};
	flex-direction: column;
	margin-bottom: 30px;
	text-align: left;
`;

export const UseCaseImage = styled.div`
	position: absolute;
	bottom: 0;
	width: 336px;
	height: 336px;
`;
