import React from 'react';
import { Link } from 'gatsby';
import { checkLink } from '../../../../utils/helpers.js';
import SectionTextBox from '../../global/section-textbox/SectionTextBox.js';
import ImageWrapper from '../../global/ImageWrapper/ImageWrapper.js';
import * as S from './styles.js';

const InfoImgBox = props => {
	const {
		title,
		subtitle,
		image1,
		image1Link,
		image2,
		image2Link,
		linkshape = 'link',
	} = props;

	const generateLink = (link, text) => {
		const linkType = checkLink(link);
		if (linkType === 2) {
			return <Link to={`${link}/`}>{text}</Link>;
		} else if (linkType === 1) {
			return (
				<a href={link} target="_self">
					{text}
				</a>
			);
		} else if (linkType === 0) {
			return (
				<a href={link} target="_blank" rel="noopener noreferrer">
					{text}
				</a>
			);
		}
	};

	return (
		<>
			<S.Wrapper linkshape={linkshape}>
				<SectionTextBox title={title} subtitle={subtitle} largeHeading="true" />
				<S.ImageWrapper>
					<S.ImageBox>
						<div className="details">
							<ImageWrapper
								image={image1}
								imageStyles={{ maxWidth: '450px', margin: '0 auto' }}
							/>
							<h2>{image1.title}</h2>
							<S.RichTextnWrapper>
								<p>{image1.description}</p>
							</S.RichTextnWrapper>

							{image1Link &&
								image1Link.link &&
								generateLink(image1Link.link, image1Link.text)}
						</div>
					</S.ImageBox>
					<S.ImageBox>
						<div className="details">
							<ImageWrapper
								image={image2}
								imageStyles={{ maxWidth: '450px', margin: '0 auto' }}
							/>
							<h2>{image2.title}</h2>
							<S.RichTextnWrapper>
								<p>{image2.description}</p>
							</S.RichTextnWrapper>

							{image2Link &&
								image2Link.link &&
								generateLink(image2Link.link, image2Link.text)}
						</div>
					</S.ImageBox>
				</S.ImageWrapper>
			</S.Wrapper>
		</>
	);
};

export default InfoImgBox;
