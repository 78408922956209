import styled from "styled-components";
import { mediaQuery, setColors, setFontFamily, setFontSize, setFontWeight } from "../../../../utils/helpers";

export const Wrapper = styled.div`
     padding: 80px 20px;
     a {
        text-align: center;
		text-decoration: none;
		display: flex;
		align-items: center;
        justify-content: center;
		margin: 20px auto;
		font-size: ${setFontSize.tiny};
		line-height: 1.3;
        width: fit-content;
        background-color: ${(props) => props.linkshape==='button' ? setColors.themeBlue : setColors.white};
        color: ${(props) => props.linkshape==='button' ? setColors.white : setColors.themeBlue};
        padding: 10px;
	}
`

export const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    ${mediaQuery.tabletXS`flex-wrap: wrap`}
`
export const ImageBox = styled.div`
    padding: 0px 50px;
    h2{
        margin-top: 40px;
        font-family: ${setFontFamily.bold};
		font-size: ${setFontSize.mediumSmall};
		line-height: 1.38;
		letter-spacing: -0.21px;
		font-weight: ${setFontWeight.bold};
		margin: 20px auto 0px auto;
        ${mediaQuery.laptopXL`font-size: ${setFontSize.smaller}`}
        ${mediaQuery.tabletL`font-size: ${setFontSize.tinySmall}`}
        ${mediaQuery.tabletXS`font-size: ${setFontSize.verySmall}`}
    }
    p{
        margin: 10px 0px !important;
        font-size: ${setFontSize.tiny} !important;
        ${mediaQuery.tabletXS`font-size: ${setFontSize.mediumTiny} !important`}
    }
    a{
        font-size: ${setFontSize.tiny} !important;
        padding-top: 0px;
        margin-top: 0px;
        ${mediaQuery.tabletXS`font-size: ${setFontSize.mediumTiny} !important`}
    }
    ${mediaQuery.tabletXS`padding: 0px 10px`}
    
`

export const RichTextnWrapper = styled.div`
	margin: 0 !important;
	padding: 0 !important;
	p {
        max-width: 420px;
		font-family: ${setFontFamily.book};
		font-size: ${setFontSize.verySmall};
		color: ${setColors.black};
		letter-spacing: -0.31px;
		line-height: 1.3;
		margin: 0 auto;
	}
	${mediaQuery.laptopXL`
        p{
            font-size: ${setFontSize.mediumTiny};
        }
    `}
	${mediaQuery.tabletL`
        p{
            font-size: ${setFontSize.veryTiny};
        }
    `}
`;